import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { Grid, TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import moment from "moment";
const DatePickers = ({
  selectedStartDate,
  setSelectedStartDate,
  selectedEndDate,
  setSelectedEndDate,
}) => {
  const handleStartDateChange = (date) => {
    setSelectedStartDate(moment(date).format("YYYY-MM-DD"));
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(moment(date).format("YYYY-MM-DD"));
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid style={{ display: "flex", gap: 15 }}>
        <DatePicker
          label="Start Date"
          value={selectedStartDate}
          onChange={handleStartDateChange}
          format="MM/dd/yyyy"
          TextFieldComponent={(props) => (
            <TextField {...props} variant="outlined" size="small" />
          )}
        />
        <DatePicker
          label="End Date"
          value={selectedEndDate}
          onChange={handleEndDateChange}
          format="MM/dd/yyyy"
          TextFieldComponent={(props) => (
            <TextField {...props} variant="outlined" size="small" />
          )}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default DatePickers;
