import React, { useEffect, useState } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";

// ACTIONs
import { getApplicantsAction } from "../../../actions/Actions";

// HOCS
import CompWrapper from "../../hocs/CompWrapper.hoc";

// COMMON
import { InvertedButton } from "../../common/button/Button.common";
import Table from "../../common/table/Table.common";

// HELPER
import { FILE_URL } from "../../../config/Config";

const ApplicantsPage = (props) => {
  const dispatch = useDispatch();
  const vacancy = useSelector((state) => state.vacancy);
  const { applicantsLoader, applicantsListCount, applicantsList } = vacancy;
  const { location, navigation, params } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  let query = useQuery();

  const [filter, setFilter] = useState("");

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getApplicantsAction(params.id, pageNumber));
  }, [getApplicantsAction, pageNumber, params.id]);

  return (
    <CompWrapper>
      <div className="newspage-container">
        <div className="newspage">
          <div className="newspage-head">
            <div className="newspage-head-title">Applicants</div>
          </div>

          <div className="newspage-list">
            <Table
              columns={[
                {
                  field: "applied_by",
                  name: "Applied By",
                  render: (rowData) => (
                    <div>
                      <img
                        alt="thumbnail"
                        src={FILE_URL + "/profile/" + rowData?.photo}
                        style={{ height: 70, width: 70, borderRadius: 35 }}
                      />
                      <p>{rowData?.name}</p>
                    </div>
                  ),
                },
                {
                  field: "applied_by",
                  name: "phone",
                  render: (rowData) => (
                    <div>
                      <p>{rowData?.phone}</p>
                    </div>
                  ),
                },
                {
                  field: "applied_by",
                  name: "email",
                  render: (rowData) => (
                    <div>
                      <p>{rowData?.email}</p>
                    </div>
                  ),
                },
                {
                  field: "applied_date",
                  name: "Date",
                  render: (rowData) => (
                    <p>{rowData && rowData?.slice(0, 10)}</p>
                  ),
                },
                {
                  field: "cover_letter",
                  name: "Cover Letter",
                },
                {
                  field: "applied_by",
                  name: "CV",
                  render: (rowData) => (
                    <button
                      onClick={() =>
                        window.open(FILE_URL + "/profile/" + rowData?.cv)
                      }>
                      View CV
                    </button>
                  ),
                },
              ]}
              data={applicantsList}
              dataLoader={applicantsLoader}
              totalCount={applicantsListCount}
            />
          </div>
        </div>
      </div>
    </CompWrapper>
  );
};

export default ApplicantsPage;
