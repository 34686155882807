import { CV, CV_PRIORITY, CV_OFFERS } from "../actions/Actions";

const initalState = {
  listLoader: false,
  listCount: null,
  list: [],
  editLoader: null,
  offersList: [],
  offersLoader: false,
  offersListCount: null,
};

export function cvReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    // GET
    case CV.LOADING:
      return {
        ...state,
        listLoader: true,
      };
    case CV.SUCCESS:
      return {
        ...state,
        listLoader: false,
        listCount: payload.total,
        list: payload.rows,
      };
    case CV.ERROR:
      return {
        ...state,
        listLoader: false,
      };

    // EDIT
    case CV_PRIORITY.LOADING:
      return {
        ...state,
        editLoader: true,
      };
    case CV_PRIORITY.SUCCESS:
      return {
        ...state,
        editLoader: false,
      };
    case CV_PRIORITY.ERROR:
      return {
        ...state,
        editLoader: false,
      };

    // GET OFFERS
    case CV_OFFERS.LOADING:
      return {
        ...state,
        offersLoader: true,
        offersList: [],
      };
    case CV_OFFERS.SUCCESS:
      return {
        ...state,
        offersLoader: false,
        offersListCount: payload.total,
        offersList: payload.rows,
      };
    case CV_OFFERS.ERROR:
      return {
        ...state,
        offersLoader: false,
      };

    default:
      return state;
  }
}
