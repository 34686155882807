import { createActionSet } from "../helpers/Helpers";

//SIGNIN ACTION SET
export const SIGNIN = createActionSet("SIGNIN");

//LOGIN ACTION SET
export const LOGIN = createActionSet("LOGIN");
export const PASSWORD = createActionSet("PASSWORD");

//NEWS ACTION SET
export const NEWS = createActionSet("NEWS");
export const ADD_NEWS = createActionSet("ADD_NEWS");
export const DELETE_NEWS = createActionSet("DELETE_NEWS");
export const NEWS_DETAIL = createActionSet("NEWS_DETAIL");
export const EDIT_NEWS = createActionSet("EDIT_NEWS");

// VACANCY
export const VACANCY = createActionSet("VACANCY");
export const EDIT_VACANCY = createActionSet("EDIT_VACANCY");
export const VACANCY_APPLICANTS = createActionSet("VACANCY_APPLICANTS");

// SERVICES
export const SERVICES = createActionSet("SERVICES");
export const ADD_SERVICE = createActionSet("ADD_SERVICE");
export const EDIT_SERVICE = createActionSet("EDIT_SERVICE");
export const DELETE_SERVICE = createActionSet("DELETE_SERVICE");
export const SERVICE_PREVIEW = createActionSet("SERVICES_PREVIEW");
export const EDIT_SERVICE_PREVIEW = createActionSet("EDIT_SERVICE_PREVIEW");

// USERS
export const USERS = createActionSet("USERS");
export const EDIT_USERS = createActionSet("EDIT_USERS");

// CV
export const CV = createActionSet("CV");
export const CV_PRIORITY = createActionSet("CV_PRIORITY");
export const CV_OFFERS = createActionSet("CV_OFFERS");

// CV
export const ORDERS = createActionSet("ORDERS");

// CV
export const REVIEWS = createActionSet("REVIEWS");
export const UPDATE_REVIEW_STATUS = createActionSet("UPDATE_REVIEW_STATUS");

//Blog ACTION SET
export const GET_BLOG = createActionSet("GET_BLOG");
export const ADD_BLOG = createActionSet("ADD_BLOG");
export const DELETE_BLOG = createActionSet("DELETE_BLOG");
export const BLOG_DETAIL = createActionSet("BLOG_DETAIL");
export const EDIT_BLOG = createActionSet("EDIT_BLOG");
