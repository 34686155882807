import React, { useEffect, useState } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import FillterTable from "../filterTable/FillterTable";
// ACTIONs
import { getUsersAction, editUserAction } from "../../actions/Actions";

// HOCS
import CompWrapper from "../hocs/CompWrapper.hoc";

// COMMON
import { InvertedButton } from "../common/button/Button.common";
import Table from "../common/table/Table.common";

// HELPER
import { FILE_URL } from "../../config/Config";

const ClientPage = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const { listLoader, listCount, list } = users;
  const { location, navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getUsersAction(pageNumber, "normal", rowsPerPage));
  }, [getUsersAction, pageNumber, rowsPerPage]);

  const toggleHandler = (data, id) => {
    dispatch(
      editUserAction(id, { active: !data }, toast, pageNumber, "worker"),
    );
  };

  return (
    <CompWrapper>
      <div className="newspage-container">
        <div className="newspage">
          <div className="newspage-head">
            <div className="newspage-head-title">Clients</div>
          </div>
          <div className="filterTable-section">
            <FillterTable
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
              selectedEndDate={selectedEndDate}
              selectedStartDate={selectedStartDate}
              setSelectedEndDate={setSelectedEndDate}
              setSelectedStartDate={setSelectedStartDate}
            />
          </div>
          <div className="newspage-list">
            <Table
              columns={[
                {
                  field: "photo",
                  render: (rowData) =>
                    rowData && (
                      <img
                        alt="thumbnail"
                        src={FILE_URL + "/profile/" + rowData}
                        style={{ height: 70, width: 70, borderRadius: 35 }}
                      />
                    ),
                },
                {
                  field: "name",
                  name: "name",
                },
                {
                  field: "phone",
                  name: "phone",
                },
                {
                  field: "email",
                  name: "email",
                },
                {
                  field: "postal_code",
                  name: "Location",
                  render: (rowData) => <p>{rowData?.value}</p>,
                },
                {
                  field: "active",
                  name: "active",
                  render: (rowData, id) => (
                    <input
                      type="checkbox"
                      checked={rowData}
                      onChange={() => toggleHandler(rowData, id)}
                    />
                  ),
                },
              ]}
              data={list}
              dataLoader={listLoader}
              totalCount={listCount}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </CompWrapper>
  );
};

export default ClientPage;
