import { VACANCY, EDIT_VACANCY, VACANCY_APPLICANTS } from "../actions/Actions";

const initalState = {
  listLoader: false,
  listCount: null,
  list: [],
  editLoader: false,
  deleteLoader: false,
  applicantsList: [],
  applicantsLoader: false,
  applicantsListCount: null,
};

export function vacancyReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    // GET
    case VACANCY.LOADING:
      return {
        ...state,
        listLoader: true,
      };
    case VACANCY.SUCCESS:
      return {
        ...state,
        listLoader: false,
        listCount: payload.total,
        list: payload.rows,
      };
    case VACANCY.ERROR:
      return {
        ...state,
        listLoader: false,
      };

    // EDIT
    case EDIT_VACANCY.LOADING:
      return {
        ...state,
        editLoader: true,
      };
    case EDIT_VACANCY.SUCCESS:
      return {
        ...state,
        editLoader: false,
      };
    case EDIT_VACANCY.ERROR:
      return {
        ...state,
        editLoader: false,
      };

    // GET APPLICANTS
    case VACANCY_APPLICANTS.LOADING:
      return {
        ...state,
        applicantsLoader: true,
        applicantsList: [],
      };
    case VACANCY_APPLICANTS.SUCCESS:
      return {
        ...state,
        applicantsLoader: false,
        applicantsListCount: payload.total,
        applicantsList: payload.rows,
      };
    case VACANCY_APPLICANTS.ERROR:
      return {
        ...state,
        applicantsLoader: false,
      };

    default:
      return state;
  }
}
