import {
  SERVICES,
  ADD_SERVICE,
  DELETE_SERVICE,
  EDIT_SERVICE,
  SERVICE_PREVIEW,
  EDIT_SERVICE_PREVIEW,
} from "../actions/Actions";

const initalState = {
  listLoader: false,
  listCount: null,
  list: [],
  editLoader: false,
  addLoader: false,
  deleteLoader: false,
  editLoader: false,
  previewLoader: false,
  singleList: null,
};

export function servicesReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    // GET
    case SERVICES.LOADING:
      return {
        ...state,
        listLoader: true,
      };
    case SERVICES.SUCCESS:
      return {
        ...state,
        listLoader: false,
        listCount: payload.total,
        list: payload.rows,
      };
    case SERVICES.ERROR:
      return {
        ...state,
        listLoader: false,
      };

    // EDIT
    case ADD_SERVICE.LOADING:
      return {
        ...state,
        addLoader: true,
      };
    case ADD_SERVICE.SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case ADD_SERVICE.ERROR:
      return {
        ...state,
        addLoader: false,
      };

    // DELETE
    case DELETE_SERVICE.LOADING:
      return {
        ...state,
        deleteLoader: true,
      };
    case DELETE_SERVICE.SUCCESS:
      return {
        ...state,
        deleteLoader: false,
      };
    case DELETE_SERVICE.ERROR:
      return {
        ...state,
        deleteLoader: false,
      };

    // DELETE
    case EDIT_SERVICE.LOADING:
      return {
        ...state,
        editLoader: true,
      };
    case EDIT_SERVICE.SUCCESS:
      return {
        ...state,
        editLoader: false,
      };
    case EDIT_SERVICE.ERROR:
      return {
        ...state,
        editLoader: false,
      };
    case SERVICE_PREVIEW.LOADING:
      return {
        ...state,
        previewLoader: true,
      };
    case SERVICE_PREVIEW.SUCCESS:
      return {
        ...state,
        singleList: payload,
        previewLoader: false,
      };
    case SERVICE_PREVIEW.ERROR:
      return {
        ...state,
        previewLoader: false,
      };
    case EDIT_SERVICE_PREVIEW.LOADING:
      return {
        ...state,
        previewLoader: true,
      };
    case EDIT_SERVICE_PREVIEW.SUCCESS:
      return {
        ...state,
        previewLoader: false,
      };
    case EDIT_SERVICE_PREVIEW.ERROR:
      return {
        ...state,
        previewLoader: false,
      };
    case EDIT_SERVICE_PREVIEW.CLEAR:
      return {
        ...state,
        singleList: null,
      };
    default:
      return state;
  }
}
