import React from "react";

const SelectInput = () => {
  return (
    <div className="select-container">
      <select name="select" className="select-input">
        <option value="Year" className="select-input__value">
          Year
        </option>
        <option value="Month" className="select-input__value">
          Month
        </option>
        <option value="Week" className="select-input__value">
          Week
        </option>
      </select>
    </div>
  );
};

export default SelectInput;
