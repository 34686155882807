import React, { useEffect, useState } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

// ACTIONs
import { deleteBlogAction, getBlogAction } from "../../actions/Actions";

// HOCS
import CompWrapper from "../hocs/CompWrapper.hoc";

// COMMON

import Table from "../common/table/Table.common";

// HELPER
import { FILE_URL } from "../../config/Config";
import { InvertedButton } from "../common/button/Button.common";

const BlogPage = () => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const dispatch = useDispatch();
  const { listLoader, listCount, list, deleteLoader } = useSelector(
    (state) => state.blogs,
  );
  const { location, navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { toast } = useAuth();
  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getBlogAction(pageNumber, rowsPerPage));
  }, [dispatch, pageNumber, rowsPerPage]);

  const onDeleteHandler = (item) => {
    dispatch(deleteBlogAction(item.blog_details.id, toast, pageNumber));
  };
  const editHandler = (item) => {
    navigate(`blog/edit/${item.blog_details.id}`);
  };
  return (
    <CompWrapper>
      <div className="newspage-container">
        <div className="newspage">
          <div className="newspage-head">
            <div className="newspage-head-title">Blog</div>
            <div className="newspage-head-button">
              <div style={{ width: 100 }}>
                <InvertedButton
                  title="Add"
                  onClick={() => {
                    navigate(routes["Add Blog"].path);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="newspage-list">
            <Table
              columns={[
                {
                  field: "blog_details",
                  name: "Client",
                  render: (rowData) => (
                    <div>
                      <img
                        alt="thumbnail"
                        src={FILE_URL + "/blogs/" + rowData?.images?.[0]}
                        style={{ height: 60, width: 60, borderRadius: 4 }}
                      />
                      <p>{rowData?.name}</p>
                    </div>
                  ),
                },

                {
                  field: "blog_details",
                  name: "Title",
                  render: (rowData) => <p>{rowData?.title_pt}</p>,
                },
                {
                  field: "blog_details",
                  name: "Status",
                  render: (rowData) => (
                    <p>{rowData.on_draft ? "Draft" : "Published"}</p>
                  ),
                },

                {
                  field: "blog_details",
                  name: "Time",
                  render: (rowData) => (
                    <p>{moment(rowData.created_at).format("LLL")}</p>
                  ),
                },
              ]}
              data={list}
              dataLoader={listLoader}
              totalCount={listCount}
              actions
              onDeleteHandler={onDeleteHandler}
              deleteLoader={deleteLoader}
              onEditHandler={editHandler}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </CompWrapper>
  );
};

export default BlogPage;
