import {
  SERVICES,
  ADD_SERVICE,
  DELETE_SERVICE,
  EDIT_SERVICE,
  SERVICE_PREVIEW,
  EDIT_SERVICE_PREVIEW,
} from "./Actions";
import { api, APIS, TABLE_LIMIT } from "../config/Config";

export function getServicesAction(page, perPage, query, startDate, endDate) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: SERVICES.LOADING });
      res = await api(
        `${APIS.services}?page=${page}&limit=${
          perPage || TABLE_LIMIT
        }&search=${query}&startDate=${startDate}&endDate=${endDate}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: SERVICES.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: SERVICES.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: SERVICES.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function postServiceAction(formdata, goBackHandler, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_SERVICE.LOADING });
      res = await api(APIS.addServices, "POST", formdata, { file: true });

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: ADD_SERVICE.SUCCESS });
        toast({ message: "Service successfully Added", type: "success" });
        goBackHandler();
      } else {
        dispatch({ type: ADD_SERVICE.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_SERVICE.ERROR });
      console.error(message);
      toast({ message: "Error Adding Service", type: "error" });
      return 0;
    }
  };
}

export function editServiceAction(
  id,
  body,
  toast,
  page,
  perPage,
  query,
  startDate,
  endDate,
) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_SERVICE.LOADING });
      res = await api(`${APIS.addServices}/${id}`, "PATCH", body, {
        file: true,
      });

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: EDIT_SERVICE.SUCCESS });
        toast({ message: "Service successfully edited", type: "success" });
        dispatch(getServicesAction(page, perPage, query, startDate, endDate));
      } else {
        dispatch({ type: EDIT_SERVICE.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_SERVICE.ERROR });
      console.error(message);
      toast({ message: "Error editing Service", type: "error" });
      return 0;
    }
  };
}

export function deleteServiceAction(
  id,
  toast,
  page,
  perPage,
  query,
  startDate,
  endDate,
) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_SERVICE.LOADING });
      res = await api(`${APIS.addServices}/${id}`, "DELETE");

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: DELETE_SERVICE.SUCCESS });
        toast({ message: "Service successfully deleted", type: "success" });
        dispatch(getServicesAction(page, perPage, query, startDate, endDate));
      } else {
        dispatch({ type: DELETE_SERVICE.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_SERVICE.ERROR });
      toast({ message: "Error Deleting Service", type: "error" });
      console.error(message);
      return 0;
    }
  };
}

export const getSinviewServiceAction = (id) => {
  return async function (dispatch) {
    try {
      dispatch({ type: SERVICE_PREVIEW.LOADING });
      const res = await api(`${APIS.servicePreview}/${id}`);
      const { success, data } = res.data;
      if (success) {
        dispatch({ type: SERVICE_PREVIEW.SUCCESS, payload: data });
      } else {
        dispatch({ type: SERVICE_PREVIEW.ERROR });
      }
    } catch (error) {
      dispatch({ type: SERVICE_PREVIEW.ERROR });
      console.log(error);
    }
  };
};
export function editSevicePrevidwAction(id, formdata, goBackHandler, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_SERVICE_PREVIEW.LOADING });
      res = await api(`${APIS.editServicePreview}/${id}`, "PATCH", formdata, {
        file: true,
      });

      const { success, data } = res.data;
      if (success) {
        dispatch({ type: EDIT_SERVICE_PREVIEW.SUCCESS });
        toast({ message: "Service successfully Edited", type: "success" });
        goBackHandler();
      } else {
        dispatch({ type: EDIT_SERVICE_PREVIEW.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_SERVICE_PREVIEW.ERROR });
      console.error(message);
      toast({ message: "Error Editing Service", type: "error" });
      return 0;
    }
  };
}
