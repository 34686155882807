import React, { useEffect, useState } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import FillterTable from "../filterTable/FillterTable";

// ACTIONs
import { getCVAction, editCvAction } from "../../actions/Actions";

// HOCS
import CompWrapper from "../hocs/CompWrapper.hoc";

// COMMON
import { InvertedButton } from "../common/button/Button.common";
import Table from "../common/table/Table.common";

// HELPER
import { FILE_URL } from "../../config/Config";

const CVPage = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const dispatch = useDispatch();
  const cv = useSelector((state) => state.cv);
  const { listLoader, listCount, list } = cv;
  const { location, navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getCVAction(pageNumber, rowsPerPage));
  }, [getCVAction, pageNumber, rowsPerPage]);

  const priorityHandler = (data, id) => {
    dispatch(editCvAction(id, { priority: data }, toast, pageNumber));
  };

  return (
    <CompWrapper>
      <div className="newspage-container">
        <div className="newspage">
          <div className="newspage-head">
            <div className="newspage-head-title">Cvs</div>
          </div>
          <div className="filterTable-section">
            <FillterTable
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
              selectedEndDate={selectedEndDate}
              selectedStartDate={selectedStartDate}
              setSelectedEndDate={setSelectedEndDate}
              setSelectedStartDate={setSelectedStartDate}
            />
          </div>
          <div className="newspage-list">
            <Table
              columns={[
                {
                  field: "user_detail",
                  name: "photo",
                  render: (rowData) =>
                    rowData && (
                      <img
                        alt="thumbnail"
                        src={FILE_URL + "/profile/" + rowData?.photo}
                        style={{ height: 70, width: 70, borderRadius: 35 }}
                      />
                    ),
                },
                {
                  field: "user_detail",
                  name: "name",
                  render: (rowData) => <p>{rowData?.name}</p>,
                },
                {
                  field: "user_detail",
                  name: "phone",
                  render: (rowData) => <p>{rowData?.phone}</p>,
                },
                {
                  field: "user_detail",
                  name: "email",
                  render: (rowData) => <p>{rowData?.email}</p>,
                },
                {
                  field: "user_detail",
                  name: "Location",
                  render: (rowData) => <p>{rowData?.postal_code?.value}</p>,
                },
                {
                  field: "user_detail",
                  name: "CV",
                  render: (rowData) => (
                    <button
                      onClick={() =>
                        window.open(FILE_URL + "/profile/" + rowData?.cv)
                      }>
                      View CV
                    </button>
                  ),
                },
                {
                  field: "user_detail",
                  name: "priority",
                  render: (rowData, id) => (
                    <select
                      onChange={(e) =>
                        priorityHandler(e.target.value, rowData?.id)
                      }>
                      <option selected={rowData?.priority === 3} value={3}>
                        Highest
                      </option>
                      <option selected={rowData?.priority === 2} value={2}>
                        Higher
                      </option>
                      <option selected={rowData?.priority === 1} value={1}>
                        High
                      </option>
                      <option selected={rowData?.priority === 0} value={0}>
                        Normal
                      </option>
                    </select>
                  ),
                },
                {
                  field: "user_detail",
                  name: "Action",
                  render: (rowData) => (
                    <button
                      onClick={() =>
                        navigate(`/cv/hiring-offers/${rowData?.id}`)
                      }>
                      Hiring Offers
                    </button>
                  ),
                },
              ]}
              data={list}
              dataLoader={listLoader}
              totalCount={listCount}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </CompWrapper>
  );
};

export default CVPage;
