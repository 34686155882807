import { combineReducers } from "redux";
import { loginReducer } from "./Login.reducer";
import { newsReducer } from "./News.reducer";
import { vacancyReducer } from "./Vacancy.reducer";
import { servicesReducer } from "./Services.reducer";
import { usersReducer } from "./Users.reducer";
import { cvReducer } from "./Cv.reducer";
import { ordersReducer } from "./Orders.reducer";
import { blogsReducer } from "./Blogs.reducer";
const rootReducer = combineReducers({
  login: loginReducer,
  news: newsReducer,
  vacancy: vacancyReducer,
  services: servicesReducer,
  users: usersReducer,
  cv: cvReducer,
  orders: ordersReducer,
  blogs: blogsReducer,
});

export default rootReducer;
