import { CV, CV_PRIORITY, CV_OFFERS } from "./Actions";
import { api, APIS, TABLE_LIMIT } from "../config/Config";
import { getServicesAction } from "./Services.action";

export function getCVAction(page, perPage) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: CV.LOADING });
      res = await api(
        `${APIS.users}/cv?&page=${page}&limit=${perPage || TABLE_LIMIT}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: CV.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: CV.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: CV.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function editCvAction(id, body, toast, page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: CV_PRIORITY.LOADING });
      res = await api(`${APIS.userPriority}/${id}`, "PATCH", body);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: CV_PRIORITY.SUCCESS });
        toast({
          message: "User prioirty successfully edited",
          type: "success",
        });
        dispatch(getCVAction(page));
      } else {
        dispatch({ type: CV_PRIORITY.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: CV_PRIORITY.ERROR });
      console.error(message);
      toast({ message: "Error editing user's priority", type: "error" });
      return 0;
    }
  };
}

export function getOffersAction(id, page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: CV_OFFERS.LOADING });
      res = await api(
        `${APIS.hiringOffers}?to=${id}&page=${page}&limit=${TABLE_LIMIT}`,
      );

      const { success, data } = res.data;
      if (success) {
        dispatch({ type: CV_OFFERS.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: CV_OFFERS.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: CV_OFFERS.ERROR });
      console.error(message);
      return 0;
    }
  };
}
