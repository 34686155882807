import {
  ADD_BLOG,
  BLOG_DETAIL,
  DELETE_BLOG,
  EDIT_BLOG,
  GET_BLOG,
} from "../actions/Actions";

const initalState = {
  listLoader: false,
  listCount: null,
  list: [],
  deleteLoader: false,
  addLoader: false,
  detailLoader: false,
  detail: null,
  editLoader: false,
};

export function blogsReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    // GET
    case GET_BLOG.LOADING:
      return {
        ...state,
        listLoader: true,
      };
    case GET_BLOG.SUCCESS:
      return {
        ...state,
        listLoader: false,
        listCount: payload.total,
        list: payload.rows,
      };
    case GET_BLOG.ERROR:
      return {
        ...state,
        listLoader: false,
      };

    // BLOG_DETAIL
    case BLOG_DETAIL.LOADING:
      return {
        ...state,
        detailLoader: true,
        detail: null,
      };
    case BLOG_DETAIL.SUCCESS:
      return {
        ...state,
        detailLoader: false,
        detail: payload,
      };
    case BLOG_DETAIL.ERROR:
      return {
        ...state,
        detailLoader: false,
      };
    case BLOG_DETAIL.CLEAR:
      return {
        ...state,
        detail: null,
      };

    case DELETE_BLOG.LOADING:
      return {
        ...state,
        deleteLoader: true,
      };
    case DELETE_BLOG.SUCCESS:
      return {
        ...state,
        deleteLoader: false,
      };
    case DELETE_BLOG.ERROR:
      return {
        ...state,
        deleteLoader: false,
      };

    case ADD_BLOG.LOADING:
      return {
        ...state,
        addLoader: true,
      };
    case ADD_BLOG.SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case ADD_BLOG.ERROR:
      return {
        ...state,
        addLoader: false,
      };
    // EDIT_BLOG
    case EDIT_BLOG.LOADING:
      return {
        ...state,
        editLoader: true,
      };
    case EDIT_BLOG.SUCCESS:
      return {
        ...state,
        editLoader: false,
      };
    case EDIT_BLOG.ERROR:
      return {
        ...state,
        editLoader: false,
      };

    default:
      return state;
  }
}
