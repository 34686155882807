import React, { useEffect, useState } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";

// ACTIONs
import { getOffersAction } from "../../../actions/Actions";

// HOCS
import CompWrapper from "../../hocs/CompWrapper.hoc";

// COMMON
import { InvertedButton } from "../../common/button/Button.common";
import Table from "../../common/table/Table.common";

// HELPER
import { FILE_URL } from "../../../config/Config";

const OffersPage = (props) => {
  const dispatch = useDispatch();
  const cv = useSelector((state) => state.cv);
  const { offersLoader, offersListCount, offersList } = cv;
  const { location, navigation, params } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getOffersAction(params.id, pageNumber));
  }, [getOffersAction, pageNumber, params.id]);

  return (
    <CompWrapper>
      <div className="newspage-container">
        <div className="newspage">
          <div className="newspage-head">
            <div className="newspage-head-title">Hiring Offers</div>
          </div>

          <div className="newspage-list">
            <Table
              columns={[
                {
                  field: "hired_by",
                  name: "Hired By [Client]",
                  render: (rowData) => (
                    <div>
                      <img
                        alt="thumbnail"
                        src={FILE_URL + "/profile/" + rowData?.photo}
                        style={{ height: 70, width: 70, borderRadius: 35 }}
                      />
                      <p>{rowData?.name}</p>
                    </div>
                  ),
                },
                {
                  field: "hired_by",
                  name: "Client's Phone",
                  render: (rowData) => (
                    <div>
                      <p>{rowData?.phone}</p>
                    </div>
                  ),
                },
                {
                  field: "hired_by",
                  name: "Clients Email",
                  render: (rowData) => (
                    <div>
                      <p>{rowData?.email}</p>
                    </div>
                  ),
                },
                {
                  field: "description",
                  name: "Offer Letter",
                  render: (rowData) => (
                    <div
                      // style={{ textAlign: "left" }}
                      dangerouslySetInnerHTML={{
                        __html: rowData,
                      }}
                    />
                  ),
                },
              ]}
              data={offersList}
              dataLoader={offersLoader}
              totalCount={offersListCount}
            />
          </div>
        </div>
      </div>
    </CompWrapper>
  );
};

export default OffersPage;
