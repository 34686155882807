import React, { useEffect, useState } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-uicomp";
import moment from "moment";
import { GiCancel } from "react-icons/all";

// ACTIONs
import {
  getServicesAction,
  deleteServiceAction,
  editServiceAction,
  getSinviewServiceAction,
} from "../../actions/Actions";

// HOCS
import CompWrapper from "../hocs/CompWrapper.hoc";
import FillterTable from "../filterTable/FillterTable";

// COMMON
import { InvertedButton } from "../common/button/Button.common";
import Table from "../common/table/Table.common";
import ActivityIndicator from "../hocs/ActivityIndicator.hoc";

// HELPER
import { FILE_URL } from "../../config/Config";
import Header from "../common/header/Header.common";

const ServicesPage = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [visible, setVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const dispatch = useDispatch();
  const services = useSelector((state) => state.services);
  const {
    listLoader,
    listCount,
    list,
    deleteLoader,
    singleList,
    previewLoader,
  } = services;
  const { location, navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  let query = useQuery();
  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(
      getServicesAction(
        pageNumber,
        rowsPerPage,
        searchQuery,
        selectedStartDate === null ? "" : selectedStartDate,
        selectedEndDate === null ? "" : selectedEndDate,
      ),
    );
  }, [
    getServicesAction,
    pageNumber,
    rowsPerPage,
    searchQuery,
    selectedEndDate,
    selectedStartDate,
  ]);

  const priorityHandler = (data, id) => {
    const formdata = new FormData();

    formdata.append("priority", data);
    dispatch(
      editServiceAction(
        id,
        formdata,
        toast,
        pageNumber,
        rowsPerPage,
        searchQuery,
        selectedStartDate === null ? "" : selectedStartDate,
        selectedEndDate === null ? "" : selectedEndDate,
      ),
    );
  };

  const deleteHandler = (item) => {
    dispatch(
      deleteServiceAction(
        item.id,
        toast,
        pageNumber,
        rowsPerPage,
        searchQuery,
        selectedStartDate === null ? "" : selectedStartDate,
        selectedEndDate === null ? "" : selectedEndDate,
      ),
    );
  };
  const watchHandler = (item) => {
    dispatch(getSinviewServiceAction(item.id));
    setVisible(true);
  };
  const editHandler = (item) => {
    navigate(`services/edit/${item.id}`);
  };

  return (
    <CompWrapper>
      <div className="newspage-container">
        <div className="newspage">
          <div className="newspage-head">
            <div className="newspage-head-title">Services</div>
            <div className="newspage-head-button">
              <div style={{ width: 100 }}>
                <InvertedButton
                  title="Add"
                  onClick={() => {
                    navigate(routes["Add Service"].path);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="filterTable-section">
            <FillterTable
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
              selectedEndDate={selectedEndDate}
              selectedStartDate={selectedStartDate}
              setSelectedEndDate={setSelectedEndDate}
              setSelectedStartDate={setSelectedStartDate}
            />
          </div>
          <div className="newspage-list">
            <Table
              columns={[
                {
                  field: "photo",
                  render: (rowData) => (
                    <img
                      alt="thumbnail"
                      src={FILE_URL + "/childcat/" + rowData}
                      style={{ height: 70, borderRadius: 4 }}
                    />
                  ),
                },
                {
                  field: "name",
                  name: "name",
                },
                {
                  field: "name_pt",
                  name: "name_pt",
                },
                {
                  field: "description",
                  name: "description",
                },
                {
                  field: "description_pt",
                  name: "description_pt",
                },
                {
                  field: "priority",
                  name: "priority",
                  render: (rowData, id) => (
                    <select
                      className="service-priority"
                      onChange={(e) => priorityHandler(e.target.value, id)}
                      value={rowData}>
                      <option value={3}>Highest</option>
                      <option value={2}>Higher</option>
                      <option value={1}>High</option>
                      <option value={0}>Normal</option>
                    </select>
                  ),
                },
              ]}
              data={list}
              dataLoader={listLoader}
              totalCount={listCount}
              actions
              deleteLoader={deleteLoader}
              onDeleteHandler={deleteHandler}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              onWatcher={watchHandler}
              onEditor={editHandler}
              // onEditHandler={editHandler}
            />
          </div>
        </div>
      </div>
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        onOutsideClick={() => setVisible(false)}>
        {previewLoader ? (
          <ActivityIndicator animating={true} />
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="service-modal">
              <div className="service-header-section">
                <h1 className="service-header-modal">
                  {singleList?.data?.task_child_name_pt}
                </h1>
                <div className="modal-close" onClick={() => setVisible(false)}>
                  <GiCancel />
                </div>
              </div>
              <div className="service-time">
                <span>Created at : </span>
                <span>
                  {moment(singleList?.data?.created_at).format("LLL")}
                </span>
              </div>
              <div className="service-modal-img">
                <img
                  src={FILE_URL + "/childcat/" + `${singleList?.data?.photo}`}
                  alt={singleList?.data?.task_child_name_pt}
                />
              </div>
              <div className="service-des-modal">
                {singleList?.data?.description_pt}
              </div>
            </div>
          </div>
        )}
      </Modal>
    </CompWrapper>
  );
};

export default ServicesPage;
