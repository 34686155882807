import React, { useState } from "react";
import { SearchInput } from "../common/inputField/InputField.common";
import DatePickers from "../common/datePicker/DatePicker";
import { Grid } from "@material-ui/core";

const FillterTable = ({
  setSearchQuery,
  searchQuery,
  selectedStartDate,
  setSelectedStartDate,
  selectedEndDate,
  setSelectedEndDate,
}) => {
  // const [searchValue, setSearchValue] = useState("");
  const searchValueChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <Grid style={{ display: "flex", gap: 15 }}>
      <form action="">
        <SearchInput
          name="name"
          value={searchQuery}
          placeholder="Search by name..."
          onChange={searchValueChange}
        />
      </form>
      <div className="date-picker-section">
        <DatePickers
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          setSelectedStartDate={setSelectedStartDate}
        />
      </div>
    </Grid>
  );
};

export default FillterTable;
