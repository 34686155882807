import LoginPage from "../loginPage/LoginPage";
import NotFoundPage from "../notFoundPage/NotFoundPage";

import CVPage from "../cvPage/CvPage";
import HiringOffersPage from "../cvPage/hiringOffers/HiringOffers";

import VacancyPage from "../vacancyPage/VacancyPage";
import ApplicantsPage from "../vacancyPage/applicants/ApplicantsPage";
import ServicesPage from "../servicesPage/ServicesPage";
import AddServicesPage from "../servicesPage/addServicesPage/AddServicesPage";
import EditServicePage from "../servicesPage/editServicePage/EditServicePage";

import WorkerPage from "../workerPage/WorkerPage";
import ClientsPage from "../clientsPage/ClientsPage";

import OrdersPage from "../ordersPage/OrdersPage";
import PaypalCheckoutButton from "../paypalPage";
import ReviewsPage from "../reviewsPage/ReviewsPage";
import BlogPage from "../blogPage/BlogPage";
import AddBlogPage from "../blogPage/addBlogPage/AddBlogPage";
import EditBlogPage from "../blogPage/editBlogPage/EditBlogPage";
import PreviewPage from "../reviewsPage/previewPage/PreviewPage";
import EditReviewPage from "../reviewsPage/editReview/EditReviewPage";
// import ManageReviewPage from "../reviewPage/ManageReviewPage";

export const publicPaths = [
  {
    name: "Root",
    path: "/",
    component: LoginPage,
    restricted: true,
  },
  {
    name: "Login",
    path: "/log-in",
    component: LoginPage,
    restricted: true,
  },
  {
    name: "Paypal",
    path: "/paypal",
    component: PaypalCheckoutButton,
    restricted: true,
  },
  {
    path: null,
    component: NotFoundPage,
  },
];

export const privatePaths = [
  // {
  //   name: "News",
  //   path: "/news",
  //   component: NewsPage,
  //   subPaths: [
  //     {
  //       name: "Add News",
  //       path: "/add",
  //       component: AddNewsPage,
  //       props: "isSub",
  //     },
  //     {
  //       name: "Edit News",
  //       path: "/edit/:id",
  //       component: EditNewsPage,
  //       props: "isSub",
  //     },
  //   ],
  // },
  {
    name: "Services",
    path: "/services",
    component: ServicesPage,
    subPaths: [
      {
        name: "Add Service",
        path: "/add",
        component: AddServicesPage,
        props: "isSub",
      },
      {
        name: "Edit Service",
        path: "/edit/:id",
        component: EditServicePage,
        props: "isSub",
      },
    ],
  },
  {
    name: "Orders",
    path: "/orders",
    component: OrdersPage,
  },
  {
    name: "CV",
    path: "/cv",
    component: CVPage,
    subPaths: [
      {
        name: "HiringOffers",
        path: "/hiring-offers/:id",
        component: HiringOffersPage,
        props: "isSub",
      },
    ],
  },
  {
    name: "Vacancy",
    path: "/vacancy",
    component: VacancyPage,
    subPaths: [
      {
        name: "Applicants",
        path: "/applicants/:id",
        component: ApplicantsPage,
        props: "isSub",
      },
    ],
  },
  {
    name: "Workers",
    path: "/workers",
    component: WorkerPage,
  },
  {
    name: "Clients",
    path: "/clients",
    component: ClientsPage,
  },
  // {
  //   name: "Reviews",
  //   path: "/reviews",
  //   component: ReviewsPage,
  // },
  {
    name: "Blog",
    path: "/blog",
    component: BlogPage,
    subPaths: [
      {
        name: "Add Blog",
        path: "/add",
        component: AddBlogPage,
        props: "isSub",
      },
      {
        name: "Edit Blog",
        path: "/edit/:id",
        component: EditBlogPage,
        props: "isSub",
      },
    ],
  },
  {
    name: "Manage Reviews",
    path: "/managereviews",
    component: ReviewsPage,
    subPaths: [
      {
        name: "Preview",
        path: "/preview",
        component: PreviewPage,
        props: "isSub",
      },
      {
        name: "Edit Review",
        path: "/edit",
        component: EditReviewPage,
        props: "isSub",
      },
    ],
  },
];
