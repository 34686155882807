import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useForm } from "react-hook-form";
import { AiFillCamera, AiFillCloseCircle } from "react-icons/ai";

// MATERIAL
import { Button, FormControlLabel, Switch } from "@material-ui/core";

// ACTIONS
import { useDispatch, useSelector } from "react-redux";
import {
  BLOG_DETAIL,
  editBlogAction,
  getBlogDetailAction,
} from "../../../actions/Actions";

// HOCS
import CompWrapper from "../../hocs/CompWrapper.hoc";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";

// COMMON
import { InputField } from "../../common/inputField/InputField.common";

// HELPERS
import { validator, isValid } from "../../../utils/Validator.util";
import { FILE_URL } from "../../../config/Api.config";
import { TextEditor } from "../../common/textEditor";

const EditBlogPage = (props) => {
  const { params } = useNavigation();
  const dispatch = useDispatch();
  const { detail } = useSelector((state) => state.blogs);

  useEffect(() => {
    dispatch(getBlogDetailAction(Number(params.id)));
    return () => dispatch({ type: BLOG_DETAIL.CLEAR });
  }, [dispatch, params.id]);
  return !!detail ? (
    <EditBlogComponent />
  ) : (
    <CompWrapper>Loading...</CompWrapper>
  );
};

const EditBlogComponent = () => {
  const { navigation, params } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const dispatch = useDispatch();
  const { detail, editLoader } = useSelector((state) => state.blogs);

  const { handleSubmit, register } = useForm();
  const [description, setDescription] = useState(() => detail?.description_pt);

  const [image, setImage] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [defaultImage, setDetfaultImage] = useState([]);
  const [deletedImage, setDeletedImage] = useState([]);
  const [isLive, setIsLive] = useState(() => !detail?.on_draft);

  useEffect(() => {
    if (!!detail) {
      // const blocksFromHtmlEng = htmlToDraft(detail.description);
      // const blocksFromHtmlPt = htmlToDraft(detail.description_pt);

      // const { contentBlocks, entityMap } = blocksFromHtmlEng;
      // const { contentBlocks: contentBlocksPt, entityMap: entityMapPt } =
      //   blocksFromHtmlPt;
      // const contentState = ContentState.createFromBlockArray(
      //   contentBlocks,
      //   entityMap,
      // );
      // const contentStateNep = ContentState.createFromBlockArray(
      //   contentBlocksPt,
      //   entityMapPt,
      // );
      // setEngEditorState(EditorState.createWithContent(contentState));
      // setPtEditorState(EditorState.createWithContent(contentStateNep));

      setDetfaultImage(detail.images);
    }
  }, [detail]);

  const onChange = (imageList) => {
    setImageError(false);
    setImage(imageList);
  };

  // const onEngChangeText = (editorState) => {
  //   setEngEditorState(editorState);
  // };
  // const onPtChangeText = (editorState) => {
  //   console.log("editorState", editorState);
  //   setPtEditorState(editorState);
  // };

  const onSubmit = (data) => {
    const catchedErros = {};
    const validate = validator(catchedErros);

    // VALIDATION

    validate(
      "imageFile",
      image?.length === 0 && deletedImage.length === defaultImage.length,
      () => {
        toast({ message: "A imagem é obrigatória", type: "error" });
      },
    );

    validate("descEng", description?.length === 0, () => {
      toast({ message: "descrição está vazia", type: "error" });
    });

    if (!isValid(catchedErros)) {
      console.error(catchedErros);
      return;
    }

    const formdata = new FormData();
    const photoFormdata = new FormData();

    formdata.append("titlePt", data.titleEng);
    formdata.append("descriptionPt", description);
    formdata.append("onDraft", !isLive);
    deletedImage.length > 0 &&
      photoFormdata.append("removeIndex", deletedImage);

    image.length > 0 &&
      image.forEach((element) => {
        formdata.append("imageFile", element.file);
      });
    console.log("formdata", formdata.getAll("imageFile"));
    dispatch(
      editBlogAction(params.id, deletedImage, formdata, goBackHandler, toast),
    );
  };

  const goBackHandler = () => {
    navigate(routes.Blog.path);
  };

  const defaultImageRemove = (index) => {
    const del = [...deletedImage];
    if (!del.includes(index)) {
      del.push(index);
      setDeletedImage(del);
    }
  };

  return (
    <CompWrapper>
      <div className="addnews-container">
        <div className="addnews">
          <form className="addnews-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Title</div>

              <InputField
                name="titleEng"
                placeholder="Title"
                innerRef={register({ required: true })}
                defaultValue={detail?.title_pt}
              />
            </div>

            {/* <div className="addnews-form-input">
              <div className="addnews-form-input-label">Description</div>
              <Editor
                name="descriptionEng"
                editorState={engEditorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEngChangeText}
              />
            </div> */}

            <TextEditor
              descriptionBody={description}
              onChange={setDescription}
              style={{ margin: `4px 0 10px` }}
            />

            {/* <div className="addnews-form-input">
              <div className="addnews-form-input-label">Title[Portuguese]</div>

              <InputField
                name="titlePt"
                innerRef={register({ required: true })}
                defaultValue={detail?.title_pt}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">
                Description[Portuguese]
              </div>
              <Editor
                name="descriptionNep"
                editorState={ptEditorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onPtChangeText}
              />
            </div> */}

            <ImageUploading
              value={image}
              onChange={onChange}
              multiple={true}
              acceptType={["png", "jpeg", "jpg"]}>
              {({ imageList, onImageUpload, onImageRemove }) => (
                <div
                  className="upload__image-wrapper"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    // // background: "#303F9F",
                    padding: 10,
                    // borderRadius: 4,
                    border: "1px dashed #303F9F",
                  }}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat( auto-fill, minmax(200px, 1fr) )",
                      columnGap: 10,
                    }}>
                    {defaultImage?.map(
                      (image, index) =>
                        !deletedImage.includes(index) && (
                          <div
                            key={index}
                            style={{
                              position: "relative",
                              width: 200,
                              height: 200,
                              overflow: "hidden",
                            }}>
                            <img
                              src={FILE_URL + "/blogs/" + image}
                              alt=""
                              style={{
                                width: "100%",
                                objectFit: "cover",
                                overflow: "hidden",
                              }}
                            />
                            <AiFillCloseCircle
                              onClick={() => defaultImageRemove(index)}
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                height: 20,
                                width: 20,
                                color: "red",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        ),
                    )}
                    {imageList.map((image, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          width: 200,
                          height: 200,
                          overflow: "hidden",
                        }}>
                        <img
                          src={image.dataURL}
                          alt=""
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            overflow: "hidden",
                          }}
                        />
                        <AiFillCloseCircle
                          onClick={() => onImageRemove(index)}
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            height: 20,
                            width: 20,
                            color: "red",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  {/* <Button
                    className="fixedwidth"
                    onClick={onImageUpload}
                    variant="contained"
                    color="primary">
                    {image?.length > 0 ? "Change Image" : "Upload image"}
                  </Button> */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",

                      marginTop:
                        image?.length > 0 || defaultImage?.length > 0 ? 10 : 0,
                    }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        background: "#303F9F",
                        padding: 10,
                        borderRadius: 4,
                        // border: "1px dashed #303F9F",
                        cursor: "pointer",
                      }}
                      onClick={onImageUpload}>
                      <AiFillCamera size={30} color="white" />
                      <div style={{ color: "white" }}>
                        {image?.length > 0 ? "Add Image" : "Upload image"}
                      </div>
                    </div>
                  </div>
                  {imageError && <div>Image Required</div>}
                </div>
              )}
            </ImageUploading>

            <FormControlLabel
              control={
                <Switch
                  checked={isLive}
                  onChange={(e) => setIsLive(e.target.checked)}
                  // name="draft"
                  color="primary"
                />
              }
              label="Publish Blog"
            />

            <ActivityIndicator animating={editLoader}>
              <Button
                className="fixedwidth"
                variant="contained"
                color="primary"
                type="submit">
                Submit
              </Button>
            </ActivityIndicator>
          </form>
        </div>
      </div>
    </CompWrapper>
  );
};

export default EditBlogPage;
