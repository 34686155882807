import {
  GET_BLOG,
  ADD_BLOG,
  DELETE_BLOG,
  BLOG_DETAIL,
  EDIT_BLOG,
} from "./Actions";
import { api, APIS, TABLE_LIMIT } from "../config/Config";

export function getBlogAction(page, perPage) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_BLOG.LOADING });
      res = await api(
        `${APIS.blogs}/dash?page=${page}&limit=${perPage || TABLE_LIMIT}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: GET_BLOG.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_BLOG.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_BLOG.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getBlogDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: BLOG_DETAIL.LOADING });
      res = await api(`${APIS.blogs}/${id}`);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: BLOG_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: BLOG_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: BLOG_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function postBlogAction(formdata, goBackHandler, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_BLOG.LOADING });
      res = await api(APIS.blogs, "POST", formdata, { file: true });

      console.log("res", res);
      const { success, data } = res.data;

      if (success) {
        dispatch({ type: ADD_BLOG.SUCCESS });
        toast({ message: data.message, type: "success" });
        goBackHandler();
      } else {
        dispatch({ type: ADD_BLOG.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_BLOG.ERROR });
      console.error(message);
      toast({ message: "Error Adding News", type: "error" });
      return 0;
    }
  };
}

export function editBlogAction(
  id,
  deleteImage,
  formdata,
  goBackHandler,
  toast,
) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_BLOG.LOADING });
      if (deleteImage.length > 0) {
        let imageres = await api(`${APIS.blogs}/image/${id}`, "DELETE", {
          index: deleteImage,
        });

        const { success: imageSuccess, data: imageData } = imageres.data;

        if (imageSuccess) {
          res = await api(`${APIS.blogs}/${id}`, "PATCH", formdata, {
            file: true,
          });

          const { success, data } = res.data;
          if (success) {
            dispatch({ type: EDIT_BLOG.SUCCESS });
            toast({ message: "Blog successfully edited", type: "success" });
            goBackHandler();
          } else {
            dispatch({ type: EDIT_BLOG.ERROR });
            toast({ message: data.message, type: "error" });
          }

          // dispatch({ type: EDIT_BLOG.SUCCESS });
          // toast({ message: "Blog successfully edited", type: "success" });
          // goBackHandler();
        } else {
          dispatch({ type: EDIT_BLOG.ERROR });
          toast({ message: imageData.message, type: "error" });
        }
      } else {
        res = await api(`${APIS.blogs}/${id}`, "PATCH", formdata, {
          file: true,
        });

        const { success, data } = res.data;
        if (success) {
          dispatch({ type: EDIT_BLOG.SUCCESS });
          toast({ message: "Blog successfully edited", type: "success" });
          goBackHandler();
        } else {
          dispatch({ type: EDIT_BLOG.ERROR });
          toast({ message: data.message, type: "error" });
        }
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_BLOG.ERROR });
      console.error(message);
      toast({ message: "Error editing Blog", type: "error" });
      return 0;
    }
  };
}

export function deleteBlogAction(id, toast, page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_BLOG.LOADING });
      res = await api(`${APIS.blogs}/${id}`, "DELETE");

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: DELETE_BLOG.SUCCESS });
        toast({ message: data.message, type: "success" });
        dispatch(getBlogAction(page));
      } else {
        dispatch({ type: DELETE_BLOG.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_BLOG.ERROR });
      toast({ message: "Error Deleting Blog", type: "error" });
      console.error(message);
      return 0;
    }
  };
}
