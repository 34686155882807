import { USERS, EDIT_USERS } from "./Actions";
import { api, APIS, TABLE_LIMIT } from "../config/Config";

export function getUsersAction(page, type, perPage) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: USERS.LOADING });
      res = await api(
        `${APIS.users}?type=${type}&page=${page}&limit=${
          perPage || TABLE_LIMIT
        }`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: USERS.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: USERS.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: USERS.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function editUserAction(id, body, toast, page, type) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_USERS.LOADING });
      res = await api(`${APIS.userStatus}/${id}`, "PATCH", body);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: EDIT_USERS.SUCCESS });
        toast({ message: "User successfully edited", type: "success" });
        dispatch(getUsersAction(page, type));
      } else {
        dispatch({ type: EDIT_USERS.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_USERS.ERROR });
      console.error(message);
      toast({ message: "Error editing User", type: "error" });
      return 0;
    }
  };
}
