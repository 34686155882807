import React, { useEffect, useState } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import FillterTable from "../filterTable/FillterTable";

// ACTIONs
import {
  getVacancyAction,
  editVacancyAction,
  editNewsAction,
} from "../../actions/Actions";

// HOCS
import CompWrapper from "../hocs/CompWrapper.hoc";

// COMMON
import { InvertedButton } from "../common/button/Button.common";
import Table from "../common/table/Table.common";

// HELPER
import { FILE_URL } from "../../config/Config";

const VacancyPage = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const dispatch = useDispatch();
  const vacancy = useSelector((state) => state.vacancy);
  const { listLoader, listCount, list } = vacancy;
  const { location, navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  let query = useQuery();

  const [filter, setFilter] = useState("");

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getVacancyAction(pageNumber, filter, rowsPerPage));
  }, [getVacancyAction, pageNumber, filter, rowsPerPage]);

  const toggleHandler = (data, id) => {
    dispatch(
      editVacancyAction(id, { urgent: !data }, toast, pageNumber, filter),
    );
  };

  const toggleVisible = (data, id) => {
    dispatch(
      editVacancyAction(id, { visible: !data }, toast, pageNumber, filter),
    );
  };

  const filterUrgent = (value) => {
    setFilter(value);
  };

  return (
    <CompWrapper>
      <div className="newspage-container">
        <div className="newspage">
          <div className="newspage-head">
            <div className="newspage-head-title">Vacancy</div>
          </div>
          <div className="filterTable-section">
            <FillterTable
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
              selectedEndDate={selectedEndDate}
              selectedStartDate={selectedStartDate}
              setSelectedEndDate={setSelectedEndDate}
              setSelectedStartDate={setSelectedStartDate}
            />
          </div>
          <div className="newspage-list">
            <select onChange={(e) => filterUrgent(e.target.value)}>
              <option value="">All</option>
              <option value="normal">Normal</option>
              <option value="urgent">Urgent</option>
            </select>
            <Table
              columns={[
                {
                  field: "title",
                  name: "title",
                },
                {
                  field: "created_by",
                  name: "Created by",
                  render: (rowData) => <p>{rowData?.name}</p>,
                },
                {
                  field: "created_by",
                  name: "Location",
                  render: (rowData) => <p>{rowData?.postal_code?.value}</p>,
                },
                {
                  field: "details",
                  name: "Description",
                  render: (rowData) => <p>{rowData?.description}</p>,
                },
                {
                  field: "details",
                  name: "Opening",
                  render: (rowData) => <p>{rowData?.opening}</p>,
                },
                {
                  field: "details",
                  name: "Experience",
                  render: (rowData) => <p>{rowData?.experience}</p>,
                },
                {
                  field: "details",
                  name: "Deadline",
                  render: (rowData) => <p>{rowData?.deadline}</p>,
                },
                {
                  field: "urgent",
                  name: "Urgent",
                  render: (rowData, id) => (
                    <input
                      type="checkbox"
                      checked={rowData}
                      onChange={() => toggleHandler(rowData, id)}
                    />
                  ),
                },
                {
                  field: "visible",
                  name: "Visible",
                  render: (rowData, id) => (
                    <input
                      type="checkbox"
                      checked={rowData}
                      onChange={() => toggleVisible(rowData, id)}
                    />
                  ),
                },
                {
                  field: "id",
                  name: "Action",
                  render: (rowData, id) => (
                    <button
                      onClick={() => navigate(`/vacancy/applicants/${id}`)}>
                      Applicants
                    </button>
                  ),
                },
              ]}
              data={list}
              dataLoader={listLoader}
              totalCount={listCount}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </CompWrapper>
  );
};

export default VacancyPage;
