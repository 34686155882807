import { VACANCY, EDIT_VACANCY, VACANCY_APPLICANTS } from "./Actions";
import { api, APIS, TABLE_LIMIT } from "../config/Config";

export function getVacancyAction(page, filter, perPage) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: VACANCY.LOADING });
      res = await api(
        `${APIS.vacancy}/dash?page=${page}&limit=${
          perPage || TABLE_LIMIT
        }&type=${filter}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: VACANCY.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: VACANCY.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: VACANCY.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function editVacancyAction(id, body, toast, page, filter) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_VACANCY.LOADING });
      res = await api(`${APIS.vacancy}/${id}`, "PATCH", body);

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: EDIT_VACANCY.SUCCESS });
        toast({ message: "Vacancy successfully edited", type: "success" });
        dispatch(getVacancyAction(page, filter));
      } else {
        dispatch({ type: EDIT_VACANCY.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_VACANCY.ERROR });
      console.error(message);
      toast({ message: "Error editing Vacancy", type: "error" });
      return 0;
    }
  };
}

export function getApplicantsAction(id, page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: VACANCY_APPLICANTS.LOADING });
      res = await api(
        `${APIS.applicants}/${id}?page=${page}&limit=${TABLE_LIMIT}`,
      );

      const { success, data } = res.data;

      if (success) {
        dispatch({ type: VACANCY_APPLICANTS.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: VACANCY_APPLICANTS.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: VACANCY_APPLICANTS.ERROR });
      console.error(message);
      return 0;
    }
  };
}
