import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { AiFillCloseCircle } from "react-icons/ai";
import { FILE_URL } from "../../../config/Api.config";

// MATERIAL
import { Button } from "@material-ui/core";

// DRAFT
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// ACTIONS
import {
  editSevicePrevidwAction,
  getSinviewServiceAction,
  EDIT_SERVICE_PREVIEW,
} from "../../../actions/Actions";

// HOCS
import CompWrapper from "../../hocs/CompWrapper.hoc";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";

// COMMON
import {
  InputField,
  TextAreaField,
} from "../../common/inputField/InputField.common";

import { validator, isValid } from "../../../utils/Validator.util";

const EditServicePage = (props) => {
  const [deletedImage, setDeletedImage] = useState([]);
  const [defaultImage, setDetfaultImage] = useState([]);

  const { navigation, params, location } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const { handleSubmit, register } = useForm();
  const dispatch = useDispatch();
  const services = useSelector((state) => state.services);
  const { singleList, previewLoader, addLoader } = services;
  const [image, setImage] = useState([]);
  const [imageError, setImageError] = useState(false);

  const onChange = (imageList) => {
    setImageError(false);
    setImage(imageList);
  };

  const onSubmit = (data) => {
    const catchedErros = {};
    const validate = validator(catchedErros);

    validate(
      "file",
      image?.length === 0 && deletedImage.length === defaultImage.length,
      () => {
        toast({ message: "A imagem é obrigatória", type: "error" });
      },
    );

    if (!isValid(catchedErros)) {
      console.error(catchedErros);
      return;
    }
    const formdata = new FormData();
    const photoFormdata = new FormData();
    formdata.append("name", data.name);
    formdata.append("description", data.description);
    formdata.append("namePt", data.name_pt);
    formdata.append("descriptionPt", data.description_pt);
    deletedImage.length > 0 &&
      photoFormdata.append("removeIndex", deletedImage);
    image.length > 0 && formdata.append("imageFile", image[0]?.file);

    dispatch(
      editSevicePrevidwAction(params.id, formdata, goBackHandler, toast),
    );
  };

  const goBackHandler = () => {
    navigate(routes.Services.path);
  };

  const defaultImageRemove = (index) => {
    const del = [...deletedImage];
    if (!del.includes(index)) {
      del.push(index);
      setDeletedImage(del);
    }
  };
  useEffect(() => {
    dispatch(getSinviewServiceAction(params.id));
    return () => dispatch({ type: EDIT_SERVICE_PREVIEW.CLEAR });
  }, [params.id, dispatch]);

  useEffect(() => {
    if (singleList?.data?.photo) {
      setDetfaultImage([singleList?.data?.photo]);
    }
  }, [singleList?.data?.photo]);

  return (
    <CompWrapper>
      <div className="addnews-container">
        <div className="addnews">
          <form className="addnews-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Name[English]</div>

              <InputField
                name="name"
                placeholder="Name[English]"
                innerRef={register({ required: true })}
                defaultValue={singleList?.data?.task_child_name}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Name[Portuguese]</div>

              <InputField
                name="name_pt"
                placeholder="Name[Portuguese]"
                innerRef={register({ required: true })}
                defaultValue={singleList?.data?.task_child_name_pt}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">
                Description[English]
              </div>

              <TextAreaField
                name="description_pt"
                placeholder="Description[English]"
                innerRef={register({ required: true })}
                defaultValue={singleList?.data?.description}
              />
            </div>
            <div className="addnews-form-input">
              <div className="addnews-form-input-label">
                Description[Portuguese]
              </div>
              <TextAreaField
                name="description"
                placeholder="Description[Portuguese]"
                innerRef={register({ required: true })}
                defaultValue={singleList?.data?.description_pt}
              />
            </div>

            <ImageUploading value={image} onChange={onChange}>
              {({ imageList, onImageUpload, onImageRemove }) => (
                <div className="upload__image-wrapper">
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat( auto-fill, minmax(200px, 1fr) )",
                      columnGap: 10,
                    }}>
                    {defaultImage?.map(
                      (image, index) =>
                        !deletedImage.includes(index) && (
                          <div
                            key={index}
                            style={{
                              position: "relative",
                              width: 200,
                              height: 200,
                              overflow: "hidden",
                            }}>
                            <img
                              src={FILE_URL + "/childcat/" + image}
                              alt=""
                              style={{
                                width: "100%",
                                objectFit: "cover",
                                overflow: "hidden",
                              }}
                            />
                            <AiFillCloseCircle
                              onClick={() => defaultImageRemove(index)}
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                height: 20,
                                width: 20,
                                color: "red",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        ),
                    )}
                    {/* {!imageList.length ? (
                      <img
                        src={FILE_URL + "/childcat/" + singleList?.data?.photo}
                        alt=""
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          overflow: "hidden",
                        }}
                      />
                    ) : null} */}
                    {imageList.map((image, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          width: 200,
                          height: 200,
                          overflow: "hidden",
                        }}>
                        <img
                          src={
                            imageList.length
                              ? image.dataURL
                              : FILE_URL +
                                "/childcat/" +
                                singleList?.data?.photo
                          }
                          alt=""
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            overflow: "hidden",
                          }}
                        />
                        <AiFillCloseCircle
                          onClick={() => onImageRemove(index)}
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            height: 20,
                            width: 20,
                            color: "red",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <Button
                    className="fixedwidth"
                    onClick={onImageUpload}
                    variant="contained"
                    color="primary">
                    Change Image
                  </Button>
                  {imageError && <div>Image Required</div>}
                </div>
              )}
            </ImageUploading>

            <ActivityIndicator animating={addLoader}>
              <Button
                className="fixedwidth"
                variant="contained"
                color="primary"
                type="submit">
                {previewLoader ? "Updating..." : "Update"}
              </Button>
            </ActivityIndicator>
          </form>
        </div>
      </div>
    </CompWrapper>
  );
};

export default EditServicePage;
