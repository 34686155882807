import React, { useEffect, useState } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import FillterTable from "../filterTable/FillterTable";

// ACTIONs
import { getOrdersAction } from "../../actions/Actions";

// HOCS
import CompWrapper from "../hocs/CompWrapper.hoc";

// COMMON
import { InvertedButton } from "../common/button/Button.common";
import Table from "../common/table/Table.common";

// HELPER
import { FILE_URL } from "../../config/Config";

const OrdersPage = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);
  const { listLoader, listCount, list } = orders;
  const { location, navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getOrdersAction(pageNumber, rowsPerPage));
  }, [getOrdersAction, pageNumber, rowsPerPage]);

  return (
    <CompWrapper>
      <div className="newspage-container">
        <div className="newspage">
          <div className="newspage-head">
            <div className="newspage-head-title">Orders</div>
          </div>
          <div className="filterTable-section">
            <FillterTable
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
              selectedEndDate={selectedEndDate}
              selectedStartDate={selectedStartDate}
              setSelectedEndDate={setSelectedEndDate}
              setSelectedStartDate={setSelectedStartDate}
            />
          </div>
          <div className="newspage-list">
            <Table
              columns={[
                {
                  field: "user_created",
                  name: "Client",
                  render: (rowData) => (
                    <div>
                      <img
                        alt="thumbnail"
                        src={FILE_URL + "/profile/" + rowData?.photo}
                        style={{ height: 60, width: 60, borderRadius: 30 }}
                      />
                      <p>{rowData?.name}</p>
                    </div>
                  ),
                },
                {
                  field: "user_assigned",
                  name: "Assigned User",
                  render: (rowData) => (
                    <div>
                      <img
                        alt="thumbnail"
                        src={FILE_URL + "/profile/" + rowData?.photo}
                        style={{ height: 60, width: 60, borderRadius: 30 }}
                      />
                      <p>{rowData?.name}</p>
                    </div>
                  ),
                },
                {
                  field: "category_details",
                  name: "Service",
                  render: (rowData) => <p>{rowData?.name}</p>,
                },
                {
                  field: "details",
                  name: "size",
                  render: (rowData) => <p>{rowData?.size}</p>,
                },
                {
                  field: "details",
                  name: "Description",
                  render: (rowData) => <p>{rowData?.description}</p>,
                },
                {
                  field: "date_time",
                  name: "Time",
                  render: (rowData) => <p>{moment(rowData).format("LLL")}</p>,
                },
                {
                  field: "location",
                  name: "location",
                  render: (rowData) => <p>{rowData?.value}</p>,
                },
              ]}
              data={list}
              dataLoader={listLoader}
              totalCount={listCount}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </CompWrapper>
  );
};

export default OrdersPage;
