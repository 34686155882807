import { USERS, EDIT_USERS } from "../actions/Actions";

const initalState = {
  listLoader: false,
  listCount: null,
  list: [],
  editLoader: false,
};

export function usersReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    // GET
    case USERS.LOADING:
      return {
        ...state,
        listLoader: true,
      };
    case USERS.SUCCESS:
      return {
        ...state,
        listLoader: false,
        listCount: payload.total,
        list: payload.rows,
      };
    case USERS.ERROR:
      return {
        ...state,
        listLoader: false,
      };

    // EDIT
    case EDIT_USERS.LOADING:
      return {
        ...state,
        editLoader: true,
      };
    case EDIT_USERS.SUCCESS:
      return {
        ...state,
        editLoader: false,
      };
    case EDIT_USERS.ERROR:
      return {
        ...state,
        editLoader: false,
      };

    default:
      return state;
  }
}
