import React, { useState } from "react";
import ImageUploading from "react-images-uploading";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { AiFillCloseCircle } from "react-icons/ai";

// MATERIAL
import { Button } from "@material-ui/core";

// DRAFT
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// ACTIONS
import { postServiceAction } from "../../../actions/Actions";

// HOCS
import CompWrapper from "../../hocs/CompWrapper.hoc";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";

// COMMON
import {
  InputField,
  TextAreaField,
} from "../../common/inputField/InputField.common";

const AddServicePage = (props) => {
  const { navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const { handleSubmit, register } = useForm();
  const dispatch = useDispatch();
  const services = useSelector((state) => state.services);
  const { addLoader } = services;
  const [image, setImage] = useState([]);
  const [imageError, setImageError] = useState(false);

  const onChange = (imageList) => {
    setImageError(false);
    setImage(imageList);
  };

  const onSubmit = (data) => {
    const formdata = new FormData();

    formdata.append("name", data.name);
    formdata.append("description", data.description);
    formdata.append("namePt", data.name_pt);
    formdata.append("descriptionPt", data.description_pt);
    formdata.append("parentId", 1);
    image.length > 0 && formdata.append("imageFile", image[0]?.file);

    dispatch(postServiceAction(formdata, goBackHandler, toast));
  };

  const goBackHandler = () => {
    navigate(routes.Services.path);
  };

  return (
    <CompWrapper>
      <div className="addnews-container">
        <div className="addnews">
          <form className="addnews-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Name[English]</div>

              <InputField
                name="name"
                placeholder="Name[English]"
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Name[Portuguese]</div>

              <InputField
                name="name_pt"
                placeholder="Name[Portuguese]"
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">
                Description[English]
              </div>

              <TextAreaField
                name="description_pt"
                placeholder="Description[English]"
                innerRef={register({ required: true })}
              />
            </div>
            <div className="addnews-form-input">
              <div className="addnews-form-input-label">
                Description[Portuguese]
              </div>

              <TextAreaField
                name="description"
                placeholder="Description[Portuguese]"
                innerRef={register({ required: true })}
              />
            </div>

            <ImageUploading value={image} onChange={onChange}>
              {({ imageList, onImageUpload, onImageRemove }) => (
                <div className="upload__image-wrapper">
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat( auto-fill, minmax(200px, 1fr) )",
                      columnGap: 10,
                    }}>
                    {imageList.map((image, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          width: 200,
                          height: 200,
                          overflow: "hidden",
                        }}>
                        <img
                          src={image.dataURL}
                          alt=""
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            overflow: "hidden",
                          }}
                        />
                        <AiFillCloseCircle
                          onClick={() => onImageRemove(index)}
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            height: 20,
                            width: 20,
                            color: "red",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <Button
                    className="fixedwidth"
                    onClick={onImageUpload}
                    variant="contained"
                    color="primary">
                    {image?.length > 0 ? "Change Image" : "Upload image"}
                  </Button>
                  {imageError && <div>Image Required</div>}
                </div>
              )}
            </ImageUploading>

            <ActivityIndicator animating={addLoader}>
              <Button
                className="fixedwidth"
                variant="contained"
                color="primary"
                type="submit">
                Submit
              </Button>
            </ActivityIndicator>
          </form>
        </div>
      </div>
    </CompWrapper>
  );
};

export default AddServicePage;
