import axios from "axios";
import { getCookie, decrypter } from "./Cookie.helper";

export function apiGenerator({ baseURL }) {
  return async function api(url, method, body, apiConfig) {
    // const token = localStorage.getItem("token");

    const enToken = getCookie("token");
    const tCheck = getCookie("gTime");
    const token = enToken && tCheck ? decrypter(enToken, tCheck) : null;

    const config = {
      method: "GET",
      baseURL,
      url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    if (method) config.method = method;
    if (body) config.data = body;
    if (apiConfig?.headers) config.headers = apiConfig.headers;

    // For file
    if (apiConfig?.file) {
      config.headers = {
        "Content-Type": `multipart/form-data`,
        Authorization: `Bearer ${token}`,
      };
      // FOR UPLOAD
      config.onUploadProgress = function (progressEvent) {
        let progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        apiConfig?.fileUploadProgress && apiConfig.fileUploadProgress(progress);
      };
      // FOR DOWNLOAD
      config.onDownloadProgress = function (progressEvent) {
        let progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        apiConfig?.fileDownloadProgress &&
          apiConfig.fileDownloadProgress(progress);
      };
    }

    let response;
    try {
      response = await axios(config);
      return response;
    } catch (e) {
      throw new Error(
        e.response.data?.data?.message ||
          e.response.data?.message ||
          e.response.data ||
          e.message,
      );
    }
  };
}
