import React, { useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigation } from "react-auth-navigation";

const PaypalCheckoutButton = () => {
  const { location } = useNavigation();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  let query = useQuery();
  const price = query.get("value");
  const desc = query.get("desc");

  const handleApprove = (order) => {
    window.ReactNativeWebView.postMessage(JSON.stringify({ order }));
  };

  return (
    <PayPalScriptProvider
      options={{
        "client-id":
          "AVA2A43lMNlDi2MyTauWaxSXAMR2aeb5qSmg_e0gA2il2wMCVdz-WRiETMizTsG79FrzJP37AIX2UDFo",
      }}>
      {price && desc && (
        <div
          style={{
            padding: 50,
            marginTop: "50%",
          }}>
          <PayPalButtons
            style={{
              color: "gold",
              layout: "horizontal",
              height: 50,
              tagline: true,
              shape: "rect",
              label: "pay",
            }}
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    description: desc,
                    amount: {
                      value: price,
                    },
                  },
                ],
              });
            }}
            onApprove={async (data, actions) => {
              const order = await actions.order.capture();

              handleApprove(order);
            }}
            onCancel={(err) => {
              console.log("error making payments cancelled", err);
              //   toast({ message: "Error making payments!", type: "error" });
            }}
            onError={(err) => {
              // toast({ message: "Error making payments!", type: "error" });
              console.log("error making payments cancelled", err);
            }}
          />
        </div>
      )}
    </PayPalScriptProvider>
  );
};

export default PaypalCheckoutButton;
