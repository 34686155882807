import { ORDERS } from "./Actions";
import { api, APIS, TABLE_LIMIT } from "../config/Config";

export function getOrdersAction(page, perPage) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ORDERS.LOADING });
      res = await api(
        `${APIS.orders}?&page=${page}&limit=${perPage || TABLE_LIMIT}`,
      );

      const { success, data } = res.data;
      if (success) {
        dispatch({ type: ORDERS.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: ORDERS.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: ORDERS.ERROR });
      console.error(message);
      return 0;
    }
  };
}
