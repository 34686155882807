import { apiGenerator } from "../helpers/Helpers";

// const token = getCookie("token"); // GET TOKEN FROM COOKIE
// const token = localStorage.getItem("token");

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_API + "/api"
    : process.env.REACT_APP_PROD_API + "/api";

// "https://api.contrate-me.com/api"; // BASE URL
export const FILE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_API
    : process.env.REACT_APP_PROD_API;

// "https://api.contrate-me.com"; // BASE FILE URL

export const TABLE_LIMIT = 5;

// API FUNCTION
export const api = apiGenerator({ baseURL: BASE_URL });

// API PATHS
export const APIS = {
  login: "/auth/admin/login",
  password: "/user/pass",
  common: "/common",
  news: "/common/type?name=news",
  vacancy: "/vacancy",
  applicants: "/applicants/vacancy",
  services: "/category/child/all",
  addServices: "/category/child",
  users: "/users/all",
  userPriority: "/users/priority",
  userStatus: "/users/status",
  cv: "/users/profile/cv",
  orders: "/tasks/all",
  hiringOffers: "/hires/all",
  blogs: "/blogs",
  servicePreview: "/category/child",
  editServicePreview: "/category/child",
};
