import React, { useEffect, useState } from "react";
import { useAuth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";

// ACTIONs
import { getUsersAction, editUserAction } from "../../actions/Actions";

// HOCS
import CompWrapper from "../hocs/CompWrapper.hoc";

// COMMON
import Table from "../common/table/Table.common";
import SelectInput from "../common/selectInput/SelectInput";

const ReviewsPage = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const { listLoader, listCount, list } = users;
  const { location, navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    dispatch(getUsersAction(pageNumber, "normal"));
  }, [getUsersAction, pageNumber]);

  const toggleHandler = (data, id) => {
    dispatch(
      editUserAction(id, { active: !data }, toast, pageNumber, "worker"),
    );
  };
  const watchHandler = () => {
    navigate(routes["Preview"].path);
  };
  const editHandler = () => {
    navigate(routes["Edit Review"].path);
  };

  return (
    <CompWrapper>
      <div className="newspage-container">
        <div className="newspage">
          <div className="newspage-head">
            <div className="newspage-head-title">Reviews</div>
            <div className="newspage-head-select">
              <SelectInput />
            </div>
          </div>

          <div className="newspage-list">
            <Table
              columns={[
                {
                  field: "name",
                  name: "Review",
                },
                {
                  field: "",
                  name: "description",
                },
                {
                  field: "phone",
                  name: "reviewed by",
                },
                {
                  field: "rating",
                  name: "Rating",
                },
                {
                  field: "active",
                  name: "approve",
                  render: (rowData, id) => (
                    <input
                      type="checkbox"
                      checked={rowData}
                      onChange={() => toggleHandler(rowData, id)}
                    />
                  ),
                },
                {
                  field: "reject",
                  name: "reject",
                  render: (rowData, id) => (
                    <input
                      type="checkbox"
                      checked={rowData}
                      onChange={() => toggleHandler(rowData, id)}
                    />
                  ),
                },
              ]}
              data={list}
              dataLoader={listLoader}
              totalCount={listCount}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              actions
              onWatcher={watchHandler}
              onEditor={editHandler}
            />
          </div>
        </div>
      </div>
    </CompWrapper>
  );
};

export default ReviewsPage;
